import { WindowLocation } from '@reach/router';

import { PlanDetailV2 } from '../PlanDetail/PlanDetailV2';

import { useQuotesPlatform } from '@hooks/useQuotesPlatform';
import { ProductCode } from '@src/types/Quote';

interface Props {
  location: WindowLocation;
}

export const QuotesPlatformAbs = (props: Props) => {
  const { getQuoteByProductCode } = useQuotesPlatform();
  const absQuote = getQuoteByProductCode(ProductCode.ABS);

  return <PlanDetailV2 quote={absQuote} {...props} />;
};
