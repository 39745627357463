import { useEffect } from 'react';
import { WindowLocation } from '@reach/router';
import { useAtomValue, useSetAtom } from 'jotai';
import { useDecision } from '@optimizely/react-sdk';

import { useAppContext } from '@contexts/AppContext';
import { AbsPage } from '@src/modules/AnnualBoilerService/Abs';
import PromoService from '@services/PromoService';
import useQuote from '@hooks/useQuote';
import { CoverType } from '@entities/enums';
import {
  boilerDetailsAtom,
  excessAtom,
  isAgentAtom,
  selectedCoverTypeAtom,
  selectedProductAtom,
} from '@src/store/store';
import { OptimizelyFeatureFlag } from '@entities/optimizely';
import { QuotesPlatformAbs } from '@src/modules/AnnualBoilerService/QuotesPlatformAbs';
import { ProductCode } from '@src/types/Quote';

interface Props {
  location: WindowLocation;
}

const Abs = (props: Props) => {
  const { verifyNoSignupInLocalStorage, promo } = useAppContext();
  const [{ enabled: useQuotesPlatform }] = useDecision(
    OptimizelyFeatureFlag.QUOTE_PLATFORM_ROLLOUT,
    {
      autoUpdate: true,
    }
  );

  const excess = useAtomValue(excessAtom);
  const boilerDetails = useAtomValue(boilerDetailsAtom);
  const setSelectedCoverType = useSetAtom(selectedCoverTypeAtom);
  const setSelectedProduct = useSetAtom(selectedProductAtom);
  const setExcess = useSetAtom(excessAtom);
  const setIsAgent = useSetAtom(isAgentAtom);

  const { quotes } = useQuote({
    promo,
    excess,
    boilerAge: parseInt(boilerDetails.boilerAge),
  });

  const absQuote = quotes.find((quote) => quote.coverType === CoverType.ABS);

  useEffect(() => {
    verifyNoSignupInLocalStorage();
    setSelectedCoverType(absQuote?.coverType);
    setSelectedProduct(ProductCode.ABS);
    setExcess(0);
    setIsAgent(false);
  }, [promo, verifyNoSignupInLocalStorage, setSelectedCoverType, setExcess, setIsAgent, absQuote]);

  useEffect(() => {
    PromoService.handlePromoFromUrl();
  }, []);

  return useQuotesPlatform ? (
    <QuotesPlatformAbs {...props} />
  ) : (
    <AbsPage {...props} quote={absQuote} />
  );
};

export default Abs;
