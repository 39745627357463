import { navigate } from 'gatsby';
import { CTAButton, Margin, Stack, TabList, Tabs } from '@ovotech/element';
import { WindowLocation } from '@reach/router';
import { useSetAtom } from 'jotai';
import styled from 'styled-components';

import { BasicPage, BulletWithIcon } from '@components';
import { ROUTE_DETAILS } from '@constants/routes';
import {
  ButtonContainer,
  PageLeftContainer,
  StyledTab,
  StyledTabPanel,
} from '@src/modules/PlanDetail/styled';
import { ABSDefaultBanner } from '@src/modules/PlanDetail/ABSDefaultBanner';
import { useAbsPromoComponentsNew } from '@hooks/useAbsPromoComponentsNew';
import { PrimaryCTA } from '@components/PrimaryCTA';
import { selectedProductAtom } from '@src/store/store';
import { mqMediumUp } from '@utils/mediaQuery';
import { IQuote } from '@src/types/Quote';
import { BasketV2 } from '@components/BasketV2/BasketV2';

interface Props {
  location: WindowLocation;
  quote?: IQuote;
}

const whatsIncluded: string[] = [
  'A visual check of the boiler',
  'The boiler being fired safely to identify any working faults',
  'A flue gas analysis efficiency test,',
  'Opening up the boiler and inspecting it, if necessary',
  'Checking the flue and ventilation are in accordance with Gas Safety (Installation and Use) Regulations',
  'Checking and adjusting the system pressure',
  'Cleaning the condensate trap',
  'Visually checking and bleeding radiators if necessary',
  'A visual check of the hot water cylinder',
  'Confirmation in writing that the service has been carried out',
  'Cleaning of the boiler’s filter (if you ask us to)',
];

const whatsNotIncluded: string[] = [
  'Repair of any faults. This is not an insurance policy.',
  'Testing the system’s inhibitor levels for the presence of sludge.',
  'Servicing of warm air boilers, back boilers or dual purpose boilers such as AGA or Rayburn.',
];
const MobileButtonContainer = styled(ButtonContainer)`
  ${mqMediumUp(`
    display: none;
  `)};
`;

const DesktopButtonContainer = styled(ButtonContainer)`
  display: none;

  ${mqMediumUp(`
    display: flex;
  `)};
`;

export const PlanDetailV2 = ({ location, quote }: Props) => {
  const setSelectedProduct = useSetAtom(selectedProductAtom);

  const { getBanner } = useAbsPromoComponentsNew();
  return (
    <BasicPage pageName="planDetail" quote={undefined} location={location}>
      {quote && getBanner(quote)}
      {!quote?.offer && <ABSDefaultBanner price={quote?.price.contract.monthly} />}
      <Margin top={6} />
      <BasicPage.Content data-testid="plan-detail-content">
        <BasicPage.Left>
          <PageLeftContainer>
            <Tabs>
              <TabList>
                <StyledTab data-testid="plan-includes-tab">What&apos;s included</StyledTab>
                <StyledTab data-testid="policy-exclusions-tab">What&apos;s not included</StyledTab>
              </TabList>
              <StyledTabPanel>
                <Stack spaceBetween={3}>
                  {whatsIncluded?.map((item) => (
                    <BulletWithIcon
                      flexStart
                      key={item}
                      text={item}
                      icon={{ name: 'check', size: 16 }}
                    />
                  ))}
                </Stack>
              </StyledTabPanel>
              <StyledTabPanel>
                <Stack spaceBetween={3}>
                  {whatsNotIncluded?.map((item) => (
                    <BulletWithIcon
                      flexStart
                      key={item}
                      text={item}
                      icon={{ name: 'cross', size: 16 }}
                    />
                  ))}
                </Stack>
              </StyledTabPanel>
            </Tabs>
            <DesktopButtonContainer>
              <PrimaryCTA
                data-testid="choose-boiler-service-desktop-cta-button"
                iconRight="chevron-right"
                onClick={() => {
                  setSelectedProduct(quote?.product);
                  navigate(`${ROUTE_DETAILS}${window.location.search}`);
                }}
              >
                Choose boiler service plan
              </PrimaryCTA>
            </DesktopButtonContainer>
          </PageLeftContainer>
          <MobileButtonContainer>
            <CTAButton
              data-testid="choose-boiler-service-mobile-cta-button"
              iconRight="chevron-right"
              onClick={() => {
                setSelectedProduct(quote?.product);
                navigate(`${ROUTE_DETAILS}${window.location.search}`);
              }}
            >
              Choose boiler service plan
            </CTAButton>
          </MobileButtonContainer>
        </BasicPage.Left>

        <BasicPage.Right>
          <BasketV2
            onClick={() => {
              setSelectedProduct(quote?.product);
              navigate(`${ROUTE_DETAILS}${window.location.search}`);
            }}
            nextText="Choose boiler service plan"
          />
        </BasicPage.Right>
      </BasicPage.Content>
    </BasicPage>
  );
};
